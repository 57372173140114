import React, { Component } from "react";
import ImageOverlay from "../ImageOverlay/ImageOverlay.js";
import "./Gallery.css";
import ArrowRight from "../../Content/Icons/ArrowRight.svg";
import ArrowLeft from "../../Content/Icons/ArrowLeft.svg";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0,
      prevImageIndex: null,
      isAnimating: false,
      direction: null, // 'next' or 'prev'
    };
  }

  componentDidMount() {
    const { autoSwipeInterval } = this.props;
    if (autoSwipeInterval) {
      this.autoSwipeTimer = setInterval(this.goToNext, autoSwipeInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoSwipeTimer);
  }

  resetAutoSwipeTimer = () => {
    const { autoSwipeInterval } = this.props;
    if (autoSwipeInterval) {
      clearInterval(this.autoSwipeTimer);
      this.autoSwipeTimer = setInterval(this.goToNext, autoSwipeInterval);
    }
  };

  goToPrevious = () => {
    if (this.state.isAnimating) return;

    const { currentImageIndex } = this.state;
    const { imageList } = this.props;
    const newIndex =
      (currentImageIndex - 1 + imageList.length) % imageList.length;

    this.setState(
      {
        prevImageIndex: currentImageIndex,
        currentImageIndex: newIndex,
        isAnimating: true,
        direction: "prev",
      },
      () => {
        setTimeout(() => {
          this.setState({ isAnimating: false, prevImageIndex: null });
        }, 500); // Match this with CSS transition duration

        this.resetAutoSwipeTimer();
      }
    );
  };

  goToNext = () => {
    if (this.state.isAnimating) return;

    const { currentImageIndex } = this.state;
    const { imageList } = this.props;
    const newIndex = (currentImageIndex + 1) % imageList.length;

    this.setState(
      {
        prevImageIndex: currentImageIndex,
        currentImageIndex: newIndex,
        isAnimating: true,
        direction: "next",
      },
      () => {
        setTimeout(() => {
          this.setState({ isAnimating: false, prevImageIndex: null });
        }, 500);

        this.resetAutoSwipeTimer();
      }
    );
  };

  renderImage = (index) => {
    const { imageList } = this.props;
    const imageData = imageList[index];

    if (!imageData) {
      return <p>No Image :/</p>;
    }

    const {
      url,
      alt,
      title,
      subtitle,
      overlayStyle,
      imageHeight,
      maxHeight,
      versionGallery,
    } = imageData;

    return (
      <ImageOverlay
        url={url}
        alt={alt}
        title={title}
        subtitle={subtitle}
        overlayStyle={overlayStyle}
        imageHeight={imageHeight || "auto"}
        maxHeight={maxHeight}
        versionGallery={versionGallery}
      />
    );
  };

  render() {
    const { currentImageIndex, prevImageIndex, isAnimating, direction } =
      this.state;

    return (
      <div className="gallery-container">
        <button
          onClick={this.goToPrevious}
          className="left"
          disabled={isAnimating}
        >
          <img src={ArrowLeft} alt="" className="arrow" height={90} />
        </button>

        <div className="gallery-slides-wrapper">
          {/* Current image */}
          <div
            className={`gallery-slide current ${
              isAnimating ? `slide-${direction}-in` : ""
            }`}
          >
            {this.renderImage(currentImageIndex)}
          </div>

          {/* Previous image (only during animation) */}
          {isAnimating && prevImageIndex !== null && (
            <div className={`gallery-slide previous slide-${direction}-out`}>
              {this.renderImage(prevImageIndex)}
            </div>
          )}
        </div>

        <button
          onClick={this.goToNext}
          className="right"
          disabled={isAnimating}
        >
          <img src={ArrowRight} alt="" className="arrow" height={90} />
        </button>
      </div>
    );
  }
}

export default Gallery;
