import React, { Component } from "react";
import "./ImageOverlay.css";

class ImageOverlay extends Component {
  render() {
    const {
      url,
      alt,
      title,
      subtitle,
      overlayStyle,
      imageHeight,
      maxHeight,
      versionGallery,
    } = this.props;

    const {
      top = "70%",
      left = "30%",
      transform = "translate(-50%, -50%)",
      textAlign = "center",
      titleColor = "#707070",
      titleFontSize = "270%",
      subtitleColor = "#007bc0",
    } = overlayStyle || {};

    const rootStyle = {
      "--top": top,
      "--left": left,
      "--transform": transform,
      "--textAlign": textAlign,
      "--titleColor": titleColor,
      "--titleFontSize": titleFontSize,
      "--subtitleColor": subtitleColor,
      "--imageHeight": imageHeight || "auto",
      "--maxHeight": maxHeight || "auto",
    };

    return (
      <div className="image-overlay-container">
        <div className="image-overlay-wrapper" style={rootStyle}>
          <img
            src={url}
            alt={alt}
            className={
              versionGallery
                ? "image-overlay-image"
                : "image-overlay-image-gallery"
            }
          />

          <div className="image-overlay-text">
            <h1 className="image-overlay-title">{title}</h1>
            <h3 className="image-overlay-subtitle">{subtitle}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageOverlay;
