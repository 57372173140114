import "./ForWhom.css";
import ScrollToTopButton from "../../Components/ScrollToTopButton/ScrollToTopButton";
import Footer from "../../Components/Footer/Footer";

const ForWhom = () => {
  return (
    <div>
      <div className="main">
        <div className="whiteSpace" />
        <h2>Kommentare</h2>
        <br />
        <h3>Zukunftsvision entwickeln</h3>
        Projektmanager
        <br />
        Industrie
        <br />
        <h4>Fundierte Expertise, Empathie und Engagement</h4>
        Die vier Monate Coaching bei Thomas Willmann waren für mich eine
        unglaublich wertvolle Erfahrung. Ich habe nicht nur meine beruflichen
        Fähigkeiten und Stärken besser kennengelernt, sondern auch eine klare
        Vision für meine berufliche Zukunft entwickelt. Thomas Willmann hat mich
        mit seiner fundierten Expertise, seiner Empathie und seinem Engagement
        beeindruckt. Ich kann das Coaching uneingeschränkt empfehlen!
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Aufschlussreiches Feedback</h3>
          Abteilungsleiter
          <br />
          Operativer Service
          <br />
          Öffentlicher Dienst
          <br />
          <h4>Differenziert, wertschätzend, hilfreich </h4>
          Thomas Willmann hat mich über ca. 1,5 Jahre begleitet. Ihm gelang es
          sehr gut, Situationen zu analysieren, gezielt nachzufragen,
          Handlungsfelder einzukreisen und Handlungsoptionen aufzuzeigen. Mit
          guten Erläuterungen und praktischen Beispielen hat er mir neue
          Sichtweisen eröffnet und Möglichkeiten dargelegt mit Situationen
          umzugehen. Dabei ging er flexibel auf aktuelle Themen und Situationen
          ein und ihm gelang es auch, nicht griffigen Situationen und
          Empfindungen meinerseits auf den Grund zu gehen und Hintergründe und
          Verhaltensmöglichkeiten zu erarbeiten. Unsere Abschlussbesprechung war
          für mich eines der aufschlussreichsten, differenziertesten und
          vielschichtigsten Feedbacks, das ich je bekommen habe. Und dabei
          zugleich wertschätzend und hilfreich. Chapeau. Ein Coach, den ich ohne
          Einschränkungen wärmsten Herzens weiterempfehlen kann.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Vielfältige Führungssituationen</h3>
        Gruppenleiterin
        <br />
        Personalentwicklung
        <br />
        Bank
        <br />
        <h4>Professionell und klar, menschlich und empathisch </h4>
        Coaching mit Thomas Willmann ist fester Bestandteil unseres
        Personalentwicklungsangebotes für unsere Führungskräfte. In unserer
        Zusammenarbeit erlebe ich Thomas Willmann sehr professionell und klar
        und ebenso menschlich und empathisch. Thomas Willmann schafft es, unsere
        neuen Führungskräfte passend zu ihrer individuellen Situation und
        jeweiligen Rahmenbedingungen so zu begleiten, dass sie in ihrer neuen
        Rolle als Führungskräfte in ihren Teams und im Gesamtunternehmen gut
        ankommen und wirksam werden. Als Coach und Sparringspartner für
        erfahrene Führungskräfte erarbeitet er vertrauensvoll, mit viel
        Fachkompetenz und Erfahrung Lösungen für herausfordernde
        Führungssituationen.
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Neue Bereichsleitung</h3>
          Bereichsleiterin
          <br />
          Organisation
          <br />
          IT-Dienstleister
          <br />
          <h4>Professionelles Sparring mit sehr hoher Fachkompetenz </h4>
          In der Zeit einer Übernahme eines neuen, größeren Bereiches konnte ich
          Herrn Willmann jederzeit als professionellen Sparringspartner mit sehr
          hoher Fachkompetenz und wertvollen, richtungsweisenden Ideen
          wahrnehmen. Durch die ausgeprägte soziale Kompetenz, das Eingehen auf
          meine jeweiligen Belange sowie das konkrete Feedback ist es gelungen,
          alle Themen zielführend umzusetzen. Eine sehr große Unterstützung!
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Top-Management-Besetzung</h3>
        Aufsichtsratsmitglied
        <br />
        Gesundheitswesen
        <br />
        <h4>Komplexe Entscheidungssituation klar strukturiert</h4>
        Thomas Willmann hat mich in einer schwierigen Aufsichtsratsproblematik
        bezüglich einer Top-Management-Besetzung hervorragend beraten. Er hat
        mich in einer gezielt geführten Diskussion motiviert, die verschiedenen
        Entscheidungsoptionen festzulegen und klar zu beschreiben, um sie dann
        in ihren möglichen Auswirkungen zu bewerten. Dieser Prozess hat es mir
        sehr erleichtert eine klare Position im Aufsichtsrat zu beziehen, der
        sich dann auch die anderen Aufsichtsratsmitglieder anschließen konnten.
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Klarheit für weiteres Vorgehen</h3>
          Director Internal Auditing
          <br />
          IT-Dienstleister
          <br />
          <h4>Ganzheitlich und zielführend</h4>
          Durch seine sowohl fundierte als auch empathische Unterstützung bei
          der Lösung aller wirklichen Probleme konnte ich meine
          Herausforderungen ganzheitlich und zielführend abschließen. Jedes
          Gespräch war sehr wertvoll, ich fühlte mich anschließend in meinem
          Handeln gestärkt und verschaffte mir Klarheit für weitere
          Vorgehensweisen. Ich bin Thomas Willmann für seine Unterstützung sehr
          dankbar und kann ihn für ein Coaching uneingeschränkt weiterempfehlen.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Abwägen von Karrierealternativen</h3>
        Bereichsleiter
        <br />
        Internet und Onlinemedien
        <br />
        Onlineversand
        <br />
        <h4>
          Wissenschaftliche Arbeitsweise + Managementerfahrung = neue Impulse
        </h4>
        Ich habe im Rahmen eines beruflichen Wechsels mit Thomas Willmann
        zusammengearbeitet. Hierbei ging es vor allem um die Herausarbeitung
        verschiedener Karrierealternativen und deren Abwägungen in Bezug auf
        meine berufliche und persönliche Entwicklung. Thomas Willmann hat mich
        systematisch und analytisch unterstützt, die für meine Situation beste
        Entscheidung zu treffen. Seine wissenschaftliche Arbeitsweise kombiniert
        mit seiner persönlichen Management-Erfahrung haben mir noch einmal neue
        Impulse gegeben und meinen Karriereweg auch über die ursprüngliche
        Zusammenarbeit hinaus beeinflusst. Ich kann Herrn Willmann
        uneingeschränkt als Coach (speziell für Management-bezogene Themen)
        empfehlen.
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Managementthemen und persönliche Entwicklung</h3>
          Abteilungsleiter
          <br />
          Produktion
          <br />
          Medienunternehmen
          <br />
          <h4>
            Entwicklungsorientiertes Coaching, fachlich wertvoll und
            hochsympathisch
          </h4>
          Thomas Willmann hat mir in mehrfacher Hinsicht wertvolle Unterstützung
          gegeben. Durch seine außergewöhnliche Führungsexpertise lieferte er
          fundierte Anregungen zu Managementthemen, die ich im Rahmen eines
          großen Umstrukturierungsprozesses erfolgreich integrieren konnte.
          Gleichzeitig begleitete er mich in persönlichen Entwicklungsfragen und
          half mir, Klarheit über meine eigene Rolle in der neuen Struktur zu
          finden. Er realisierte damit ein Coaching, das sowohl die Perspektiven
          des „Außen“ als auch des „Innen“ berücksichtigte und damit einen
          systemischen Blick auf meine Gesamtsituation ermöglichte. Diese
          Kombination dieser unterschiedlichen Schwerpunkte, die ansonsten
          meiner Erfahrung nach nur bei unterschiedlich spezialisierten Coaches
          zu finden sind, brachte Thomas Willmann in Personalunion auf eine Art
          und Weise in die Beratung ein, die nicht nur hoch kompetent und
          nutzbringend, sondern gleichzeitig unprätentiös, auf Augenhöhe und
          äußert sympathisch war.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Entscheidungen sicher treffen</h3>
        Abteilungsleiterin
        <br />
        Wirtschaftsdienstleister
        <br />
        <h4>Professionell und empathisch</h4>
        Herr Willmann hat es verstanden, mich mit gezielten und kurzen Fragen zu
        einer klaren und sicheren Entscheidung zu führen. Er konnte die
        Rahmenbedingungen und die abgewogenen Argumente schnell einordnen und in
        seiner ruhigen und erfahrenen Art meinen Blickwinkel auf die Problematik
        und die Lösungsmöglichkeiten klärend erweitern. Als Coach und
        Sparring-Partner kann ich Herrn Willmann nur empfehlen.
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Change mit Kompetenz und Erfahrung</h3>
          Geschäftsführer
          <br />
          Öffentlicher Dienst
          <br />
          <h4>Angenehme Zusammenarbeit</h4>
          Sehr strukturiertes und fachlich fundiertes Coaching durch Herrn
          Willmann. Geht sehr gut auf die spezifischen Belange des Auftraggebers
          ein und bereichert durch seine Methodenkompetenz, seine
          Sozialkompetenz und seine reichhaltigen beruflichen Erfahrungen, auch
          in Change-Prozessen. Zudem sehr angenehm in der persönlichen
          Zusammenarbeit.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Onboarding</h3>
        Abteilungsleiter
        <br />
        Drittvermittler & Direktverkauf
        <br />
        Finanzdienstleistungen
        <br />
        <h4>Individuelles und flexibles Coaching mit viel Sachverstand</h4>
        Thomas Willmann hat mich nach der Übernahme einer neuen
        Führungsverantwortung mit einem 100-Tage-Coaching begleitet. Alle Frage-
        und Problemstellungen einer "jungen" Führungskraft wurden ausführlich,
        sehr individuell und mit sehr viel Sachverstand behandelt. Es ergaben
        sich für mich viele wertvolle Impulse für meine tägliche Arbeit.
        Besonders gut hat gefallen, dass das Coaching digital in 1,5 Std.
        Sessions abgehalten wurde – die perfekte Gelegenheit, die Inhalte und
        Lösungsansätze in der Praxis anzuwenden! Durch die Begleitung über
        mehrere Monate konnten wir immer auf den vorherigen Einheiten aufbauen,
        aber auch spontan auf aktuelle Situationen eingehen. Herrn Willmann habe
        ich als absoluten Experten in der Finanz-/Bausparbranche erlebt, der es
        schafft, auch komplexe Fragestellungen mit viel Klarheit und
        Sachverstand „einfach“ zu transportieren. Vielen Dank für die sehr gute
        Vorbereitung!
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Führungskompetenz ausbauen</h3>
          Teamleiterin
          <br />
          Content Marketing und Brand Engagement
          <br />
          Telekommunikation
          <br />
          <h4>Anschauliche und gut umsetzbare Lösungsangebote</h4>
          Thomas Willmann ist ein Coach, den ich voll empfehlen kann: sachlich
          fundiert und menschlich sympathisch. Er schafft es, komplexe
          Sachverhalte sehr anschaulich und verständlich darzustellen und
          begreifbare und gut umsetzbare Lösungen anzubieten. Ich habe meine
          Zielsetzung zu 100 % erreicht: Fokussierung, Führungskompetenz
          ausbauen sowie klare Kommunikation im Führungsalltag.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Start-up: Von der Idee bis zum Markteintritt</h3>
        CEO & Co-Founder
        <br />
        Personaldienstleistungen
        <br />
        <h4>Die richtigen Dinge richtig tun</h4>
        Thomas Willmann hat unser Start-up-Team in der Konzeption, durch die
        Gründungsphase und bis zur Pilotierung am Markt begleitet. Uns hat
        besonders gut die methodische Vorgehensweise gefallen, die mit
        zahlreichen wertvollen Erfahrungen aus jahrelanger Berufspraxis ergänzt
        wurde; dies ist mit Sicherheit die richtige Basis für eine zielführende
        Beratung! Dennoch, eine wirklich gute Beratung baut auf Verständnis und
        Vertrauen. Und genau das ist der Grund dafür, warum die Zusammenarbeit
        für uns so gut funktioniert hat. Wir hatten zu jedem Zeitpunkt das
        Gefühl, dass wir uns in guten Händen befinden und dabei die richtigen
        Dinge richtig tun. Und daher kommt es bis heute nicht selten vor, dass
        sich jemand in einer langatmigen Diskussion auf einen der vielen
        Ratschläge besinnt, um die anderen wieder auf Kurs zu führen!
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Change-Management</h3>
          Bereichsleiterin
          <br />
          Head of HR Development & Reward
          <br />
          Finanzdienstleistungen
          <br />
          <h4>Konkrete Beispiele und Erfahrungswerte </h4>
          Ein Coaching mit Herrn Willmann kann ich sehr empfehlen. Durch seine
          eigene langjährige Berufspraxis als Führungskraft verfügt er selbst
          über konkrete Beispiele und Erfahrungswerte, die das Coaching im
          Ergebnis gut greifbar und in die Praxis übertragbar machen.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Karriereentscheidung treffen</h3>
        Abteilungsleiter
        <br />
        Risikocontrolling / Meldewesen
        <br />
        Finanzdienstleistungen
        <br />
        <h4>Wertvolle Beratungsgespräche</h4>
        Das Coaching war geprägt von einer professionellen Bestandsaufnahme der
        Ist-Situation und einer klaren Beschreibung des Coaching-Zielbildes.
        Herr Willmann hat dabei das Können, stets die Bedürfnisse des Coachees
        aufzunehmen und durch seinen großen praktischen Erfahrungsschatz sowie
        durch seine exzellenten fachlichen Kenntnisse im Coaching-Bereich in ein
        wertvolles Beratungsgespräch zu übertragen.
        <br />
      </div>

      <div className="blue">
        <div className="main">
          <h3>Geschäftsführungsfunktion strukturieren</h3>
          Leiter
          <br />
          Projekt- und Projektportfoliomanagement
          <br />
          Mess- und Regeltechnik
          <br />
          <h4>Businessthemen und persönliche Weiterentwicklung im Blick</h4>
          Thomas Willmann hat mich bei der erstmaligen Übernahme einer
          Geschäftsführungsfunktion unterstützt. Neben den Businessthemen rund
          um Vision und Strategie, Strukturen und Kultur hat er es sehr gut
          verstanden mich auch persönlich auf meine neue Rolle als
          Geschäftsführer vorzubereiten. Dabei beleuchteten wir unter anderem
          die Chancen und Risiken des Geschäftsmodells sowie die Chance und
          Risiken meiner persönlichen kurz- und mittelfristigen beruflichen
          Weiterentwicklung bei der avisierten Positionsübernahme. Dies
          veranschaulichte Thomas Willmann sehr gut mit konkreten Beispielen;
          dadurch waren die Themen für mich griffig und unmissverständlich
          einzuordnen. Die Kombination aus professioneller Begleitung zu
          Businessthemen zum einen und die für mich anstehenden persönlichen
          Veränderungen bei der Übernahme der Geschäftsführungsaufgabe zum
          anderen, war und ist für mich einzigartig. Thomas Willmann ist einen
          Coach, der beides vereint. Dabei versteht er es, eine super angenehmen
          Gesprächsatmosphäre zu schaffen. Ich kann Thomas Willmann
          uneingeschränkt weiterempfehlen.
          <br />
        </div>
      </div>

      <div className="main">
        <h3>Strategie und Struktur</h3>
        Founder und Technical Leader
        <br />
        Software
        <br />
        <h4>Sehr effektiv und echt klasse!</h4>
      </div>

      <div className="blue">
        <div className="main">
          <h3>Erste 100 Tage</h3>
          Bereichsleiterin
          <br />
          Kundenservice und Bestandsmanagement
          <br />
          Finanzdienstleistungen
          <br />
          <h4>Sehr guter und praxiserfahrener Coach</h4>
        </div>
      </div>

      <ScrollToTopButton />
      <Footer />
    </div>
  );
};
export default ForWhom;
